// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/fonts/HelveticaNeueCyr-Medium.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 550;
  font-display: swap;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("ttf");
}
.errorPage_wrapper__o8Ub7 {
  width: 100%;
  background: #FFF;
  min-height: 100dvh;
}

.errorPage_logo__BCZqw {
  width: 174px;
  height: 132px;
}

.errorPage_title__A6j7f {
  font-weight: 500;
  color: #1D273A;
  font-size: 64px;
  font-family: "Helvetica Neue";
  margin-bottom: 50px;
}
@media (max-width: 1024px) {
  .errorPage_title__A6j7f {
    font-size: 44px;
  }
}
@media (max-width: 576px) {
  .errorPage_title__A6j7f {
    font-size: 40px;
    margin-bottom: 10px;
  }
}

.errorPage_text__qzacB {
  color: #1D273A;
  font-size: 21px;
  font-weight: 500;
  font-family: "Helvetica Neue";
}
@media (max-width: 576px) {
  .errorPage_text__qzacB {
    font-size: 18px;
  }
}

.errorPage_container__PIE70 {
  padding: 9%;
  row-gap: 20px;
  display: flex;
  flex-direction: column;
}

.errorPage_button__CQOln {
  max-width: 220px;
  background: linear-gradient(90.66deg, #6279e6 0.57%, #847aea 48%, #8867e6 96.43%);
  border-radius: 12px;
  height: 40px;
  padding: 10px 20px;
  text-align: center;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-family: "Helvetica Neue";
  color: #FFF;
  text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/components/error/errorPage.module.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;EACA,kBAAA;EACA,gBAAA;EACA,kBAAA;EACA,0DAAA;AACF;AAEA;EACE,WAAA;EACA,gBAAA;EACA,kBAAA;AAAF;;AAGA;EACE,YAAA;EACA,aAAA;AAAF;;AAGA;EACE,gBAAA;EACA,cAAA;EACA,eAAA;EACA,6BAAA;EACA,mBAAA;AAAF;AACE;EANF;IAOI,eAAA;EAEF;AACF;AAAE;EAVF;IAWI,eAAA;IACA,mBAAA;EAGF;AACF;;AAAA;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,6BAAA;AAGF;AAFE;EALF;IAMI,eAAA;EAKF;AACF;;AAFA;EACE,WAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;AAKF;;AAFA;EACE,gBAAA;EACA,iFAAA;EACA,mBAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;EACA,6BAAA;EACA,WAAA;EACA,qBAAA;AAKF","sourcesContent":["@font-face {\n  font-family: 'Helvetica Neue';\n  font-style: normal;\n  font-weight: 550;\n  font-display: swap;\n  src: url('../../assets/fonts/HelveticaNeueCyr-Medium.ttf') format('ttf');\n}\n\n.wrapper {\n  width: 100%;\n  background: #FFF;\n  min-height: 100dvh;\n}\n\n.logo {\n  width: 174px;\n  height: 132px;\n}\n\n.title {\n  font-weight: 500;\n  color: #1D273A;\n  font-size: 64px;\n  font-family: \"Helvetica Neue\";\n  margin-bottom: 50px;\n  @media (max-width: 1024px) {\n    font-size: 44px;\n  }\n\n  @media (max-width: 576px) {\n    font-size: 40px;\n    margin-bottom: 10px;\n  }\n}\n\n.text {\n  color: #1D273A;\n  font-size: 21px;\n  font-weight: 500;\n  font-family: \"Helvetica Neue\";\n  @media (max-width: 576px) {\n    font-size: 18px;\n  }\n}\n\n.container {\n  padding: 9%;\n  row-gap: 20px;\n  display: flex;\n  flex-direction: column;\n}\n\n.button {\n  max-width: 220px;\n  background: linear-gradient(90.66deg, #6279e6 0.57%, #847aea 48%, #8867e6 96.43%);\n  border-radius: 12px;\n  height: 40px;\n  padding: 10px 20px;\n  text-align: center;\n  border: none;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-weight: 500;\n  font-family: \"Helvetica Neue\";\n  color: #FFF;\n  text-decoration: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `errorPage_wrapper__o8Ub7`,
	"logo": `errorPage_logo__BCZqw`,
	"title": `errorPage_title__A6j7f`,
	"text": `errorPage_text__qzacB`,
	"container": `errorPage_container__PIE70`,
	"button": `errorPage_button__CQOln`
};
export default ___CSS_LOADER_EXPORT___;
