import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import back from '../../assets/terms/back.svg';
import styles from './policy.module.scss';

const Policy = React.memo(() => {
  useEffect(() => {
    document.documentElement.style.scrollBehavior = 'initial';
    document.body.style.scrollBehavior = 'initial';
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
    return () => {
      document.documentElement.style.scrollBehavior = 'smooth';
      document.body.style.scrollBehavior = 'smooth';
    };
  }, []);

  return (
    <div className={`container ${styles.policyContainer}`}>
      <Link to="/" className={styles.backBtn}>
        <img src={back} alt="back" />
        <span type="button">Назад</span>
      </Link>
      <div className={styles.date}>
        <p>в редакции от «21» декабря 2022 г.</p>
      </div>
      <div className={styles.title}>
        <h1>
          Политика обработки <br /> персональных данных
        </h1>
      </div>
      <section className={styles.contentSection}>
        <div className={styles.textWrapper}>
          <p>
            Настоящая политика обработки персональных данных (далее – Политика){' '}
            <b>Общества с ограниченной ответственностью «АГЕНТСТВО АЛЕКСА ВЕБЕРА»</b> (далее –
            Общество) разработана в соответствии с нормами Федерального закона от 27.07.2006 №152-ФЗ
            «О персональных данных» (далее – Закон о персональных данных) и определяет случаи и
            порядок обработки Обществом персональных данных, а также порядок обеспечения Обществом
            безопасности и конфиденциальности такой информации при взаимодействии Общества с
            пользователями Платформы «10Q».
          </p>
        </div>
      </section>
      <section className={styles.contentSection}>
        <h2 className={styles.contentTitle}>1. Основные термины и определения </h2>
        <div className={styles.textWrapper}>
          <p>
            <b>Платформа</b> – общая совокупность всех информационных ресурсов (в том числе сайт
            (сайты) Общества в сети Интернет, мобильные приложения), информационных технологий и
            комплекса программно-технических средств, обеспечивающих функционирование Платформы
            «10Q» (https://tenq.ru/).
          </p>
          <p>
            <b>Пользователь</b> – физическое лицо (субъект персональных данных), в отношении
            которого осуществляется обработка персональных данных, полученных Обществом при
            взаимодействии в рамках Платформы либо в связи с функционированием Платформы.
          </p>
          <p>
            <b>Обработка персональных данных</b> – любое действие (операция) или совокупность
            действий (операций) с персональными данными, совершаемых с использованием средств
            автоматизации или без их использования, в том числе сбор, запись, систематизацию,
            накопление, хранение, уточнение (обновление, изменение), извлечение, использование,
            передачу (распространение, предоставление, доступ), обезличивание, блокирование,
            удаление, уничтожение.
          </p>
          <p>
            <b>Персональные данные</b> – любая информация, относящаяся к прямо или косвенно
            определенному или определяемому физическому лицу (Пользователю).
          </p>
          <p>
            <b>Предоставление персональных данных</b> – действия, направленные на раскрытие
            персональных данных определенному лицу или определенному кругу лиц.
          </p>
          <p>
            <b>Распространение персональных данных</b> – действия, направленные на раскрытие
            персональных данных неопределенному кругу лиц.
          </p>
          <p>
            <b>Сбор персональных данных</b> – действия, направленные на получение персональных
            данных субъекта персональных данных (Пользователя), включая аудиозапись, фото- и
            видеосъемку.
          </p>
          <p>
            <b>Cookies</b> – небольшой фрагмент данных, которые Платформа с разрешения Пользователя
            отправляет через веб-браузер на жесткий диск компьютера Пользователя, что дает
            возможность Платформе узнавать браузер Пользователя, собирать и запоминать информацию о
            Пользователе, запоминать и обрабатывать информацию о работе Пользователя в Платформе,
            распознавать настройки Пользователя для будущих посещений, собирать общие данные о
            трафике и взаимодействиях с Платформой. Пользователь может принять или отклонить
            Cookies. Большинство веб-браузеров автоматически принимают Cookies, однако Пользователь
            может самостоятельно изменить свои настройки и полностью запретить в браузере прием всех
            файлов Cookies.
          </p>
          <p>
            <b>Посетитель заведения</b> – дееспособное физическое лицо, осуществляющее пользование
            Платформой в целях совершения заказа и/или оплаты товара, перечисления чаевых и
            совершения иных действий в соответствии с условиями пользовательского соглашения для
            Посетителей (https://tenq.ru/info).
          </p>
          <p>
            <b>Заведение</b> – юридическое лицо или индивидуальный предприниматель, заключившие
            Договор с Оператором и осуществляющее приготовление и/или реализацию (куплю-продажу)
            товара Посетителям заведения при оформлении ими Заказа.
          </p>
          <p>
            <b>Сотрудник</b> – дееспособное физическое лицо, состоящее в трудовых и/или иных
            отношениях с Заведением, и осуществляющее пользование Платформой на условиях
            пользовательского соглашения для Сотрудников (https://tenq.ru/info).
          </p>
          <p>
            <b>Уполномоченный представитель Заведения</b> – физическое лицо, представляющее интересы
            Заведения и осуществляющее управление Платформой от имени Заведения. Иные термины
            используются в соответствии с законодательством Российской Федерации.
          </p>
        </div>
      </section>
      <section className={styles.contentSection}>
        <h2 className={styles.contentTitle}>2. Общие положения</h2>
        <div className={styles.textWrapper}>
          <p>
            2.1. Настоящая Политика размещается на сайте https://tenq.ru/info, а также в мобильных
            приложениях «10Q» и «10Q Assistant», и определяет порядок обработки персональных данных,
            собираемых (получаемых) Обществом при взаимодействии с Пользователем через сайты и
            мобильные приложения Платформы, при личном общении, общении по телефону, в мессенджерах
            и социальных сетях, по электронной почте, а также через другие информационные ресурсы
            (сайты и сервисы), где опубликована настоящая Политика.
          </p>
        </div>
        <div className={styles.textWrapper}>
          <p>
            2.2. Общество собирает (получает) персональные данные Пользователя в следующих случаях:
          </p>
          <p>2.2.1. Пользователь регистрируется в Платформе;</p>
          <p>2.2.2. Пользователь использует функции Платформы;</p>
          <p>
            2.2.3. Пользователь заполняет специальные формы на сайтах или в мобильных приложениях
            Платформы (например, форму обратной связи);
          </p>
          <p>
            2.2.4. Пользователь сообщает Обществу какие-либо сведения о себе по любым доступным
            каналам связи (например, лично, по телефону, по электронной почте, в чате, в
            мессенджерах, социальных сетях или любым иным способом) в связи с использованием
            Платформы;{' '}
          </p>
          <p>
            2.2.5. В иных случаях, когда Пользователь предоставляет Обществу какую-либо информацию в
            связи с использованием Платформы.
          </p>
        </div>
        <div className={styles.textWrapper}>
          <p>
            2.3. Пользователь предоставляет Обществу согласие на обработку его персональных данных
            путем совершения одного любого действия из нижеуказанных действий:
          </p>
          <p>
            2.3.1. Пользователь продолжает использовать Платформу после просмотра уведомления о
            предполагаемой обработке его персональных данных;
          </p>
          <p>
            2.3.2. Пользователь предоставляет Обществу персональные данные для использования
            функционала Платформы;
          </p>
          <p>2.3.3. Пользователь подписывается на получение от Платформы рассылок и уведомлений;</p>
          <p>
            2.3.4. Пользователь проставляет соответствующую отметку и(или) нажимает кнопку «Я
            согласен», «Отправить», либо совершает иные подобные действия, которые свидетельствуют о
            предоставлении Пользователем Обществу его согласия на обработку персональных данных.
          </p>
        </div>
        <div className={styles.textWrapper}>
          <p>
            2.4. По вопросам обработки персональных данных, предоставленных Пользователем при
            использовании Платформы третьему лицу, Пользователь обращается непосредственно к
            третьему лицу. Общество не несёт ответственность за действия третьих лиц по обработке
            персональных данных, предоставленных Пользователем непосредственно третьему лицу при
            использовании Платформы. Пользователь самостоятельно ознакамливается с соответствующими
            документами третьих лиц, регулирующими их взаимоотношения по поводу обработки
            персональных данных.
          </p>
        </div>
      </section>
      <section className={styles.contentSection}>
        <h2 className={styles.contentTitle}>3. Основания обработки персональных данных </h2>
        <div className={styles.textWrapper}>
          <p>3.1. Основаниями для обработки Обществом персональных данных являются:</p>
          <p>3.1.1. Соглашение, заключаемое между Обществом и Пользователем;</p>
          <p>3.1.2. Письменное, электронное и иное обращение Пользователя к Обществу;</p>
          <p>
            3.1.3. Нормы законодательства Российской Федерации, обязывающие Общество совершать
            определенные действия с персональными данными;{' '}
          </p>
          <p>
            3.1.4. Нормы законодательства Российской Федерации, предоставляющие Обществу право
            совершать определенные действия с персональными данными;
          </p>
          <p>
            3.1.5. Согласие Пользователя на совершение Обществом определенных действий с его
            персональными данными при иных взаимоотношениях Общества с Пользователем.
          </p>
        </div>
      </section>
      <section className={styles.contentSection}>
        <h2 className={styles.contentTitle}>4. Цели обработки персональных данных</h2>
        <div className={styles.textWrapper}>
          <p>4.1. Целями обработки Обществом персональных данных являются:</p>
          <p>
            4.1.1. Заключение, исполнение и прекращение соглашений, сторонами которых являются
            Общество и Пользователь;
          </p>
          <p>
            4.1.2. Аутентификация Пользователя для предоставления ему доступа к Платформе и(или) к
            отдельным её функциям;
          </p>
          <p>4.1.3. Проведение Обществом акций, опросов и т.п.;</p>
          <p>
            4.1.4. Рассмотрение и учет обращений (запросов, предложений, замечаний, жалоб,
            комментариев, претензий, благодарностей) Пользователя;
          </p>
          <p>
            4.1.5. Рассылка Пользователю рекламных, специальных и информационных сообщений в
            СМС-сообщениях и(или) на адрес электронной почты или иным способом, предоставленным
            Пользователем Обществу для связи;
          </p>
          <p>
            4.1.6. Проведение с персональными данными статистического анализа и иных подобных
            действий, необходимых для совершенствования функций Платформы;
          </p>
          <p>
            4.1.7. Иные конкретные цели, указанные Пользователем в предоставленном Обществу согласии
            на обработку определенных персональных данных, указанных в данном согласии.
          </p>
        </div>
      </section>
      <section className={styles.contentSection}>
        <h2 className={styles.contentTitle}>5. Категории Пользователей</h2>
        <div className={styles.textWrapper}>
          <p>
            5.1. Настоящая Политика распространяется на обработку персональных данных следующих
            категорий Пользователей:
          </p>
          <p>5.1.1. Посетитель заведения;</p>
          <p>5.1.2. Уполномоченный представитель Заведения;</p>
          <p>5.1.3. Сотрудник;</p>
          <p>
            5.1.4. Физическое лицо, обратившееся в Общество по поводу функционирования Платформы.
          </p>
        </div>
        <div className={styles.textWrapper}>
          <p>
            5.2. Обществом осуществляется обработка следующих персональных данных Посетителя
            заведения:
          </p>
          <p>5.2.1. фамилия, собственное имя, отчество (если таковое имеется);</p>
          <p>5.2.2. номера телефонов и адрес электронной почты;</p>
          <p>
            5.2.3. данные об используемых платежных инструментах (реквизиты банковских платежных
            карточек и т. п.):
            <ul>
              <li>сведения о платежной системе (MasterCard, Visa, Мир).</li>
            </ul>
          </p>
          <p>
            5.2.4. данные о транзакциях, проводимых Посетителем заведения:
            <ul>
              <li>тип платежа;</li>
              <li>время и сумма транзакции.</li>
            </ul>
          </p>
          <p>5.2.5. идентификатор в интернете (IP-адрес);</p>
          <p>
            5.2.6. данные о Cookies:
            <ul>
              <li>дата входа в Платформу;</li>
              <li>количество посещенных страниц;</li>
              <li>длительность пользовательской сессии;</li>
              <li>точки входа (сторонние сайты, с которых осуществляется переход);</li>
              <li>
                точки выхода (ссылки на сайты, по которым осуществляется переход на сторонние
                сайты);
              </li>
              <li>страна;</li>
              <li>регион;</li>
              <li>провайдер;</li>
              <li>используемый браузер;</li>
              <li>используемое устройство;</li>
              <li>системные языки;</li>
              <li>операционная система;</li>
              <li>разрешение экрана;</li>
              <li>количество цветов экрана;</li>
              <li>сетевой адрес;</li>
              <li>рекламный или иной идентификатор устройства;</li>
              <li>информация о геолокации.</li>
            </ul>
          </p>
          <p>
            5.2.7. записи, полученные в результате переписки с Посетителем заведения (переписка по
            электронной почте, в мессенджерах, чатах и т.п.), видео- и аудиосвязи.{' '}
          </p>
        </div>
        <div className={styles.textWrapper}>
          <p>
            5.3. Обществом осуществляется обработка следующих персональных данных Уполномоченного
            представителя Заведения:
          </p>
          <p>5.3.1. фамилия, собственное имя, отчество (если таковое имеется);</p>
          <p>5.3.2. номера телефонов и адрес электронной почты;</p>
          <p>5.3.3. идентификатор в интернете (IP-адрес);</p>
          <p>
            5.3.4. данные о Cookies:
            <ul>
              <li>дата входа в Платформу;</li>
              <li>количество посещенных страниц;</li>
              <li>длительность пользовательской сессии;</li>
              <li>точки входа (сторонние сайты, с которых осуществляется переход);</li>
              <li>
                точки выхода (ссылки на сайты, по которым осуществляется переход на сторонние
                сайты);
              </li>
              <li>страна;</li>
              <li>регион;</li>
              <li>провайдер;</li>
              <li>используемый браузер;</li>
              <li>используемое устройство;</li>
              <li>системные языки;</li>
              <li>операционная система;</li>
              <li>разрешение экрана; </li>
              <li>количество цветов экрана;</li>
              <li>сетевой адрес;</li>
              <li>рекламный или иной идентификатор устройства;</li>
              <li>информация о геолокации.</li>
            </ul>
          </p>
          <p>
            5.3.5. данные об используемых платежных инструментах (реквизиты банковских платежных
            карточек и т. п.).{' '}
          </p>
          <p>
            5.3.6. данные о транзакциях, совершенных в адрес Заведения:
            <ul>
              <li>тип платежа;</li>
              <li>время и сумма транзакции.</li>
            </ul>
          </p>
          <p>
            5.3.7. записи, полученные в результате переписки с Пользователем (переписка по
            электронной почте, в мессенджерах, чатах и т.п.), видео- и аудиосвязи.
          </p>
        </div>
        <div className={styles.textWrapper}>
          <p>5.4. Обществом осуществляется обработка следующих персональных данных Сотрудника:</p>
          <p>5.4.1. фамилия, собственное имя, отчество (если таковое имеется);</p>
          <p>5.4.2. номера телефонов и адрес электронной почты;</p>
          <p>
            5.4.3. данные об используемых платежных инструментах (реквизиты банковских платежных
            карточек и т. п.):
            <ul>
              <li>сведения о платежной системе (MasterCard, Visa, Мир);</li>
            </ul>
          </p>
          <p>
            5.4.4. данные о транзакциях, проводимых Сотрудником:
            <ul>
              <li>тип платежа;</li>
              <li>время и сумма транзакции;</li>
            </ul>
          </p>
          <p>5.4.5. идентификатор в интернете (IP-адрес);</p>
          <p>
            5.4.6. данные о Cookies:
            <ul>
              <li>дата входа в Платформу;</li>
              <li>количество посещенных страниц;</li>
              <li>длительность пользовательской сессии;</li>
              <li>точки входа (сторонние сайты, с которых осуществляется переход);</li>
              <li>
                точки выхода (ссылки на сайты, по которым осуществляется переход на сторонние
                сайты);
              </li>
              <li>страна;</li>
              <li>регион;</li>
              <li>провайдер;</li>
              <li>используемый браузер;</li>
              <li>используемое устройство;</li>
              <li>системные языки;</li>
              <li>операционная система;</li>
              <li>разрешение экрана;</li>
              <li>количество цветов экрана;</li>
              <li>сетевой адрес;</li>
              <li>рекламный или иной идентификатор устройства;</li>
              <li>информация о геолокации.</li>
            </ul>
          </p>
          <p>
            5.4.7. записи, полученные в результате переписки с Сотрудником (переписка по электронной
            почте, в мессенджерах, чатах и т.п.), видео- и аудиосвязи.{' '}
          </p>
        </div>
        <div className={styles.textWrapper}>
          <p>
            5.5. Обществом осуществляется обработка следующих персональных данных физического лица,
            обратившегося в Общество по поводу функционирования Платформы:
          </p>
          <p>5.5.1. фамилия, собственное имя, отчество (если таковое имеется);</p>
          <p>5.5.2. номера телефонов;</p>
          <p>5.5.3. адрес электронной почты;</p>
          <p>
            5.5.4. записи, полученные в результате переписки с Пользователем (переписка по
            электронной почте, в мессенджерах, чатах и т.п.), видео- и аудиосвязи.
          </p>
        </div>
        <div className={styles.textWrapper}>
          <p>
            5.6. Обществом могут обрабатываться любые другие персональные данные в отношении
            Пользователя, которые Пользователь добровольно предоставил Обществу либо которые
            получены Обществом в соответствии с законодательством Российской Федерации.
          </p>
        </div>
      </section>
      <section className={styles.contentSection}>
        <h2 className={styles.contentTitle}>6. Права Пользователя</h2>
        <div className={styles.textWrapper}>
          <p>
            6.1. Пользователь имеет право на получение информации, касающейся обработки Обществом
            его персональных данных, при этом:
          </p>
          <p>
            6.1.1. Право Пользователя на доступ к его персональным данным может быть ограничено в
            соответствии с федеральными законами.
          </p>
          <p>
            6.1.2. Объем предоставляемой информации, касающейся обработки персональных данных
            Пользователя, определяется Законом о персональных данных.
          </p>
          <p>
            6.1.3. Информация, касающаяся обработки персональных данных Пользователя,
            предоставляется Пользователю или его представителю Обществом при обращении либо при
            получении запроса Пользователя или его представителя. Запрос должен содержать номер
            основного документа, удостоверяющего личность Пользователя или его представителя,
            сведения о дате выдачи указанного документа и выдавшем его органе, сведения,
            подтверждающие участие Пользователя в отношениях с Обществом (номер договора, дата
            заключения договора, условное словесное обозначение и(или) иные сведения), либо
            сведения, иным образом подтверждающие факт обработки персональных данных Обществом,
            подпись Пользователя или его представителя. Запрос может быть направлен в форме
            электронного документа и подписан электронной подписью в соответствии с
            законодательством Российской Федерации. Запрос в письменном виде направляется
            Пользователем на почтовый адрес Общества: 117105, г. Москва, Новоданиловская наб., д.6,
            корп. 1, 10 этаж, помещение XXXVI, ком. 3.
          </p>
        </div>
        <div className={styles.textWrapper}>
          <p>
            6.1. Пользователь имеет право на получение информации, касающейся обработки Обществом
            его персональных данных, при этом:
          </p>
          <p>
            6.1.1. Право Пользователя на доступ к его персональным данным может быть ограничено в
            соответствии с федеральными законами.
          </p>
          <p>
            6.1.2. Объем предоставляемой информации, касающейся обработки персональных данных
            Пользователя, определяется Законом о персональных данных.
          </p>
          <p>
            6.1.3. Информация, касающаяся обработки персональных данных Пользователя,
            предоставляется Пользователю или его представителю Обществом при обращении либо при
            получении запроса Пользователя или его представителя. Запрос должен содержать номер
            основного документа, удостоверяющего личность Пользователя или его представителя,
            сведения о дате выдачи указанного документа и выдавшем его органе, сведения,
            подтверждающие участие Пользователя в отношениях с Обществом (номер договора, дата
            заключения договора, условное словесное обозначение и(или) иные сведения), либо
            сведения, иным образом подтверждающие факт обработки персональных данных Обществом,
            подпись Пользователя или его представителя. Запрос может быть направлен в форме
            электронного документа и подписан электронной подписью в соответствии с
            законодательством Российской Федерации. Запрос в письменном виде направляется
            Пользователем на почтовый адрес Общества: 117105, г. Москва, Новоданиловская наб., д.6,
            корп. 1, 10 этаж, помещение XXXVI, ком. 3.
          </p>
        </div>
        <div className={styles.textWrapper}>
          <p>
            6.2. Пользователь имеет право требовать от Общества уточнения его персональных данных,
            их блокирования или уничтожения в случае, если персональные данные являются неполными,
            устаревшими, неточными, незаконно полученными или не являются необходимыми для
            заявленной цели обработки, а также принимать предусмотренные законом меры по защите
            своих прав.
          </p>
        </div>
        <div className={styles.textWrapper}>
          <p>
            6.3. Пользователь имеет право на отзыв согласия на обработку персональных данных в любое
            время, при этом:
          </p>
          <p>
            6.3.1. Общество в течение 10 дней с момента получения уведомления Пользователя об отзыве
            согласия на обработку персональных данных удаляет соответствующие персональные данные
            Пользователя, за исключением случаев, указанных в подпункте 6.3.2. настоящей Политики.
          </p>
          <p>
            6.3.2. В случае отзыва Пользователем согласия на обработку его персональных данных
            Общество вправе продолжить обработку персональных данных без согласия Пользователя в
            следующих случаях:
            <ul>
              <li>
                обработка персональных данных необходима для исполнения договора, стороной которого
                является Пользователь, а также для заключения договора по инициативе Пользователя;
              </li>
              <li>
                при наличии иных оснований, предусмотренных законодательством Российской Федерации.
              </li>
            </ul>
          </p>
          <p>
            6.3.3. При отзыве Пользователем своего согласия на обработку его персональных данных
            Общество прекращает совершать действия, из-за которых Обществом было получено согласие
            на обработку персональных данных, в том числе это может повлечь прекращение договорных и
            иных отношений между Обществом и Пользователем.
          </p>
        </div>
        <div className={styles.textWrapper}>
          <p>
            6.4. Пользователь имеет право обжаловать в уполномоченный орган по защите прав субъектов
            персональных данных или в судебном порядке неправомерные действия или бездействие
            Общества при обработке его персональных данных.
          </p>
        </div>
        <div className={styles.textWrapper}>
          <p>
            6.5. Пользователь имеет право на осуществление иных прав, предусмотренных
            законодательством Российской Федерации.
          </p>
        </div>
        <div className={styles.textWrapper}>
          <p>
            6.6. В случае отказа Пользователя от предоставления своего согласия на обработку
            Обществом его персональных данных Общество имеет право отказаться от совершения тех или
            иных действий, которые планировались к совершению после получения согласия Пользователя
            на обработку его персональных данных.
          </p>
        </div>
        <div className={styles.textWrapper}>
          <p>
            6.7. Пользователь для отзыва своего согласия на обработку персональных данных направляет
            Обществу уведомление с пометкой «Отзыв согласия на обработку персональных данных» в
            электронном виде на электронный адрес Общества: support@tenq.ru, или в письменном виде
            на почтовый адрес Общества: 117105, г. Москва, Новоданиловская наб., д.6, корп. 1, 10
            этаж, помещение XXXVI, ком. 3.
          </p>
        </div>
        <div className={styles.textWrapper}>
          <p>
            6.8. Рассмотрение Обществом обращений Пользователя при реализации им своих прав,
            предусмотренных настоящей Политикой, осуществляется в сроки, предусмотренные
            законодательством Российской Федерации.
          </p>
        </div>
      </section>
      <section className={styles.contentSection}>
        <h2 className={styles.contentTitle}>7. Обязанности Общества</h2>
        <div className={styles.textWrapper}>
          <p>7.1. Общество обязано:</p>
          <p>
            7.1.1. Предоставлять Пользователю (представителю Пользователя) по его запросу
            информацию, касающуюся обработки его персональных данных;
          </p>
          <p>
            7.1.2. Организовывать обработку персональных данных в порядке, установленном действующим
            законодательством Российской Федерации;
          </p>
          <p>
            7.1.3. Отвечать на обращения и запросы Пользователя и его представителя в соответствии с
            требованиями Закона о персональных данных;
          </p>
          <p>
            7.1.4. Сообщать в уполномоченный орган по защите прав субъектов персональных данных по
            запросу этого органа необходимую информацию в течение 30 дней с даты получения такого
            запроса;
          </p>
          <p>
            7.1.5. Публиковать или иным образом обеспечивать неограниченный доступ к настоящей
            Политике в отношении обработки персональных данных Пользователей Платформы;
          </p>
          <p>
            7.1.6. Принимать правовые, организационные и технические меры для защиты персональных
            данных от неправомерного или случайного доступа к ним, уничтожения, изменения,
            блокирования, копирования, предоставления, распространения персональных данных, а также
            от иных неправомерных действий в отношении персональных данных;
          </p>
          <p>
            7.1.7. Прекратить передачу (распространение, предоставление, доступ) персональных
            данных, прекратить обработку и уничтожить персональные данные в порядке и случаях,
            предусмотренных Законом о персональных данных;
          </p>
          <p>7.1.8. Исполнять иные обязанности, предусмотренные Законом о персональных данных.</p>
        </div>
      </section>
      <section className={styles.contentSection}>
        <h2 className={styles.contentTitle}>
          8. Срок обработки персональных данных, включая их хранение
        </h2>
        <div className={styles.textWrapper}>
          <p>
            8.1. Срок обработки Обществом персональных данных Пользователя, включая их хранение,
            является неограниченным.
          </p>
        </div>
        <div className={styles.textWrapper}>
          <p>
            8.2. Условием прекращения обработки персональных данных является отзыв согласия
            Пользователя на обработку его персональных данных.
          </p>
        </div>
        <div className={styles.textWrapper}>
          <p>
            8.3. Общество также прекращает обработку персональных данных конкретного Пользователя в
            случае выявления факта неправомерной обработки его персональных данных.
          </p>
        </div>
        <div className={styles.textWrapper}>
          <p>
            8.4. Законодательством Российской Федерации или договором, заключенным Обществом с
            Пользователем, могут предусматриваться иные сроки обработки персональных данных при
            истечении срока действия договора или при расторжении договора.
          </p>
        </div>
        <div className={styles.textWrapper}>
          <p>
            8.5. Для хранения персональных данных Общество использует собственные вычислительные
            мощности и вычислительные мощности своих партнеров, расположенные на территории
            Российской Федерации.
          </p>
        </div>
      </section>
      <section className={styles.contentSection}>
        <h2 className={styles.contentTitle}>9. Передача персональных данных третьим лицам</h2>
        <div className={styles.textWrapper}>
          <p>
            9.1. Общество может передавать или предоставлять доступ к персональным данным
            Пользователя, если это необходимо для достижения заявленных целей обработки персональных
            данных или необходимо в силу требований законодательства Российской Федерации, следующим
            лицам:
            <ul>
              <li>ООО «Бест2пей» (ИНН 7813531811);</li>
              <li>
                Юридические или физические лица, в случаях, когда Пользователь выразил согласие на
                это или когда это необходимо для исполнения договора, по которому Пользователь
                являетесь стороной или выгодоприобретателям;
              </li>
              <li>
                Юридическим лицам, с которыми Обществом заключены соответствующие договоры с целью
                обеспечения функционирования Платформы;
              </li>
              <li>
                Должностным лицам и органам государственного управления в случаях, когда это
                предусмотрено действующим законодательством Российской Федерации;
              </li>
              <li>Юридическим лицам, оказывающим Обществу аудиторские или юридические услуги;</li>
              <li>
                Юридическому лицу, которому Обществом уступлены права или обязанности по договору
                (соглашению), заключенному с Пользователем;
              </li>
              <li>Юридическому лицу, являющемуся правопреемником Общества. </li>
            </ul>
          </p>
        </div>
        <div className={styles.textWrapper}>
          <p>9.2. Обществом не осуществляется трансграничная передача персональных данных.</p>
        </div>
      </section>
      <section className={styles.contentSection}>
        <h2 className={styles.contentTitle}>
          10. Организация работы с персональными данными, включая меры безопасности
        </h2>
        <div className={styles.textWrapper}>
          <p>
            10.1. Общество осуществляет сбор, запись, систематизацию, накопление, хранение,
            уточнение (обновление, изменение), извлечение, использование, передачу (распространение,
            предоставление, доступ), обезличивание, блокирование, удаление и уничтожение
            персональных данных.
          </p>
        </div>
        <div className={styles.textWrapper}>
          <p>
            10.2. Обработка персональных данных может осуществляться Обществом как с использованием
            средств автоматизации, так и без использования средств автоматизации.
          </p>
        </div>
        <div className={styles.textWrapper}>
          <p>
            10.3. При обработке персональных данных Общество предпринимает необходимые правовые,
            организационные и технические меры для защиты персональных данных от неправомерного или
            случайного доступа, уничтожения, изменения, блокирования, копирования, предоставления,
            распространения, а также от иных неправомерных действий.
          </p>
        </div>
        <div className={styles.textWrapper}>
          <p>
            10.4. Общество для обеспечения целостности, безопасности и конфиденциальности
            персональных данных применяет следующие меры, к числу которых могут относиться:
            <ul>
              <li>
                определение угроз безопасности персональных данных при их обработке в информационных
                системах Общества;{' '}
              </li>
              <li>
                применение организационных и технических мер по обеспечению безопасности
                персональных данных при их обработке в информационных системах Общества, необходимых
                для выполнения требований к защите персональных данных, исполнение которых
                обеспечивает установленные действующим законодательством Российской Федерации уровни
                защищенности персональных данных;{' '}
              </li>
              <li>
                оценка эффективности принимаемых мер по обеспечению безопасности персональных данных
                до ввода в эксплуатацию информационных систем Общества;
              </li>
              <li>
                обнаружение фактов несанкционированного доступа к персональным данным и принятие мер
                по устранению причин несанкционированного доступа;
              </li>
              <li>
                восстановление персональных данных, модифицированных или уничтоженных вследствие
                несанкционированного доступа к ним;
              </li>
              <li>
                установление правил доступа к персональным данным, обрабатываемым в информационных
                системах Общества;
              </li>
              <li>
                обеспечение регистрации и учета всех действий, совершаемых с персональными данными в
                информационных системах Общества;
              </li>
              <li>
                контроль за принимаемыми мерами по обеспечению безопасности персональных данных и
                уровнями защищенности информационных систем Общества;
              </li>
              <li>
                назначение лиц, ответственных за организацию обработки и обеспечение безопасности
                персональных данных; ознакомление работников Общества, получающих доступ к
                персональным данным или участвующих в их обработке, с требованиями законодательства
                Российской Федерации по обработке и обеспечению безопасности персональных данных,
                настоящей Политикой и другими локальными нормативными актами Общества по вопросам
                обработки и обеспечения безопасности персональных данных.
              </li>
            </ul>
          </p>
        </div>
        <div className={styles.textWrapper}>
          <p>
            10.5. Общество не несет ответственности за содержание персональных данных,
            предоставляемых Пользователями.
          </p>
        </div>
        <div className={styles.textWrapper}>
          <p>
            10.6. Общество не несет ответственности и не может контролировать использование другими
            лицами любой информации, которая может быть передана Пользователем третьим лицам при
            использовании Платформы, равно как и за содержание личной информации или любой другой
            информации, получаемой от других Пользователей.
          </p>
        </div>
        <div className={styles.textWrapper}>
          <p>
            10.7. Общество не несет никакой ответственности за проверку, точность личной информации
            или другой информации, предоставленной третьими лицами либо за использование таковой
            информации Пользователями.
          </p>
        </div>
      </section>
      <section className={styles.contentSection}>
        <h2 className={styles.contentTitle}>11. Заключительные положения </h2>
        <div className={styles.textWrapper}>
          <p>
            11.1. Все предложения и вопросы в отношении персональных данных, а также в отношении
            настоящей Политики следует направлять в электронном виде по адресу электронной почты
            support@tenq.ru, или в письменном виде на почтовый адрес: 117105, г. Москва,
            Новоданиловская наб., д.6, корп. 1, 10 этаж, помещение XXXVI, ком. 3.
          </p>
        </div>
        <div className={styles.textWrapper}>
          <p>
            11.2. Настоящая Политика (в действующей редакции) размещена на сайте Общества по адресу
            https://tenq.ru/info.
          </p>
        </div>
        <div className={styles.textWrapper}>
          <p>
            11.3. Общество вправе вносить изменения и дополнения в настоящую Политику без согласия
            Пользователя.
          </p>
        </div>
        <div className={styles.textWrapper}>
          <p>
            11.4. Настоящая Политика (в новой редакции) вступает в силу с момента ее размещения на
            сайте Общества.
          </p>
        </div>
        <div className={styles.textWrapper}>
          <p>
            11.5. Пользователь обязуется самостоятельно следить за изменениями и дополнениями
            настоящей Политики путем ознакомления с ее актуальной редакцией на вышеуказанном сайте.
          </p>
        </div>
      </section>
      <section className={styles.contentSection}>
        <h2 className={styles.contentTitle}>12. Реквизиты Общества</h2>
        <div className={styles.textWrapper}>
          <p>ООО «АГЕНТСТВО АЛЕКСА ВЕБЕРА»</p>
          <p>ИНН 4401191617, КПП 772601001</p>
          <p>
            Адрес: 117105, г. Москва, вн. тер. г. Муниципальный округ Донской, наб Новоданиловская,
            д. 6, к. 1, помещ. 3/36
          </p>
          <p>АО «Тинькофф Банк» г. Москва, БИК 044525974</p>
          <p>р/с 40702810910000535577, к/с 30101810145250000974</p>
        </div>
      </section>
    </div>
  );
});

Policy.displayName = 'Policy';

export default Policy;
